<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Starter component
 */
export default {
  page: {
    title: "Master Data",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Master Data",
      
      requests: [
        
        {
          title: "Référentiels",
          route: "master_data.referentiels",
          description: "",
          icon: "referentiels",
          guard: [
            'create_administration_direction',
            'update_administration_direction',
            'read_administration_direction',
            'delete_administration_direction',
            'list_administration_direction',
            'create_administration_division',
            'update_administration_division',
            'create_administration_meeting_room',
            'delete_administration_meeting_room',
            'update_administration_meeting_room',
            'create_administration_department',
            'read_administration_division',
            'delete_administration_division',
            'list_administration_division',
            'update_administration_department',
            'read_administration_department',
            'delete_administration_department',
            'list_administration_department',
            'create_administration_service',
            'update_administration_service',
            'read_administration_service',
            'delete_administration_service',
            'list_administration_service',
            'create_administration_zone',
            'update_administration_zone',
            'read_administration_zone',
            'delete_administration_zone',
            'list_administration_zone',
            'create_administration_sector',
            'update_administration_sector',
            'read_administration_sector',
            'delete_administration_sector',
            'list_administration_sector',
            'create_administration_bloc',
            'update_administration_bloc',
            'read_administration_bloc',
            'delete_administration_bloc',
            'list_administration_bloc',
            'create_hosting_room',
            'update_hosting_room',
            'read_hosting_room',
            'delete_hosting_room',
            'list_hosting_room',
            'create_administration_office',
            'update_administration_office',
            'read_administration_office',
            'delete_administration_office',
            'list_administration_office',
            'read_administration_meeting_room',
            'list_administration_meeting_room',
            'create_administration_restaurant',
            'update_administration_restaurant',
            'read_administration_restaurant',
            'delete_administration_restaurant',
            'list_administration_restaurant',
            'create_product',
            'edit_product',
            'delete_product',
            'create_product_categ',
            'edit_product_categ',
            'delete_product_categ',
            'view_product_categ',
            'list_product_categ',
            'create_warehouse',
            'edit_warehouse',
            'delete_warehouse',
            'view_warehouse',
            'list_warehouse',
            'create_supplier',
            'edit_supplier',
            'delete_supplier',
            'view_supplier',
            'list_supplier',
          ],
          disabled: false,
        },

        {
          title: "Fiche base de vie",
          route: "settings.lifebases",
          description: "",
          icon: "lifebase",
          guard: [ "create_camp", "update_camp", "read_camp", "delete_camp", "list_camp",
          ],
          disabled: false,

        },

        {
          title: "Fiche contrat",
          route: "base.contracts.contracts.index",
          description: "",
          icon: "contract",
          guard: ["create_contract", "edit_contract", "delete_contract", "view_contract", "list_contract"],
          disabled: false,
        },

      ],
      items: [],
    };
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row settings">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xs-12 col-md-4 col-lg-4 setting-item"  v-for="request in requests" v-bind:key="request.title">
                <router-link :to="!request.disabled && canAny(request.guard) ?  { name: request.route } : '' " :class="{ 'disabledItem': !canAny(request.guard)  || request.disabled}">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="require('@/assets/images/base/menu/' + request.icon +'.png')"
                          :alt="request.title"
                          :title="request.title"
                          class="rounded-0"
                        />
                        <h4 class="card-title" >{{request.title}}</h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                        additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-none">
      <div
        class="col-lg-4 azmt-service-card"
        v-for="request in requests"
        v-bind:key="request.title"
      >
        <router-link v-if="canAny(request.guard)" :to="!request.disabled ? { name: request.route } : '' " :class="request.disabled ? 'disabledItem' : ''">
          <b-card no-body>
            <b-row no-gutters class="align-items-center">
              <b-col md="8">
                <b-card-body :title="request.title"> </b-card-body>
              </b-col>
              <b-col md="4">
                <b-card-img
                  :src="
                    require('@/assets/images/base/menu/' +
                      request.icon +
                      '.png')
                  "
                  :alt="request.title"
                  :title="request.title"
                  class="rounded-0"
                ></b-card-img>
              </b-col>
            </b-row>
          </b-card>
        </router-link>
      </div>
    </div>
  </Layout>
</template>
